<script setup>
import { reactive, ref } from "vue";
import { useReservationsStore } from "@/stores/reservationsStore";
import { storeToRefs } from "pinia";
import { formatDateDDMMYYYY } from "@/helpers/datesHelpers";
import { useRouter } from "vue-router/composables";
import CompButtonPrimaryRed from "@/components/buttons/CompButtonPrimaryRed.vue";
import CompButtonCustom from "@/components/buttons/CompButtonCustom.vue";
import CompSelectableCalendar from "@/components/calendar/datepicker/CompSelectableCalendar.vue";
import GeneralModal from "@/components/modals/GeneralModal.vue";
import CompReservationSpaceBookingCard from "@/components/reservations/CompReservationSpaceBookingCard.vue";

const router = useRouter();
const {
  isBookingModalOpen,
  bookingPlaceOptions,
  bookingStep,
  bookingIsHighSeason,
} = storeToRefs(useReservationsStore());
const {
  closeBookingModal,
  actGetBookingPlaceOptions,
  setBookingDatesSelected,
  selectBookingPlace,
} = useReservationsStore();

const startDate = ref(null);
const endDate = ref(null);
const bookingPlacesFetched = ref(false);
const currentTexts = reactive({
  1: {
    title: "Selecciona la fechas que deseas reservar",
    subtitle:
      "No puedes seleccionar fechas de temporada alta y baja al mismo tiempo",
  },
  2: {
    title: "Selecciona el lugar",
    subtitle:
      "Estos son los espacios que tenemos disponibles para ti, segun las fechas que seleccionaste :",
  },
});
const modalWidth = reactive({
  1: { md: 600, lg: 600 },
  2: {
    md: 700,
    lg: 900,
    xl: 1000,
  },
}); // Ancho inicial del modal

const setFromDate = (date) => {
  startDate.value = date?.Dateymd;
};

const setToDate = (date) => {
  endDate.value = date?.Dateymd;
};
const closeModal = () => {
  closeBookingModal();
  bookingStep.value = 1;
};

const nextStep = async () => {
  if (bookingStep.value === 1) {
    if (startDate.value && endDate.value) {
      await actGetBookingPlaceOptions({
        start_date: startDate.value,
        end_date: endDate.value,
      });
      setBookingDatesSelected({
        start_date: startDate.value,
        end_date: endDate.value,
      });
      bookingPlacesFetched.value = true;
      bookingStep.value = 2;
    }
  }
};

const goToStep = (step) => {
  bookingStep.value = step;
  if (step === 1) {
    bookingPlacesFetched.value = false;
  }
};

const continueBooking = async (place) => {
  bookingStep.value = 3;
  selectBookingPlace(place);
  closeBookingModal();
  await router.push(`/reservations/space/${place.booking_place_id}/book`);
};
</script>
<template>
  <portal to="outer-modals">
    <general-modal
      :maxHeight="{ base: 600, md: 650, lg: 650, xl: 700 }"
      :modalWidth="modalWidth[bookingStep]"
      modal-name="date-picker"
      v-if="isBookingModalOpen"
      :is-show-modal="isBookingModalOpen"
      overall
      width-fit
      @close="closeModal()"
    >
      <div class="tw-h-full tw-overflow-y-scroll invisible-scrollbar">
        <div
          class="tw-flex tw-flex-col tw-gap-0 tw-mt-1"
          v-if="
            (!bookingPlacesFetched && bookingStep === 1) ||
            bookingPlaceOptions.length > 0
          "
        >
          <p
            class="tw-text-center tw-text-sm lg:tw-text-lg tw-font-robotoBold tw-m-0"
          >
            {{ currentTexts[bookingStep].title }}
          </p>
          <p
            class="tw-text-center tw-font-roboto tw-text-xs lg:tw-text-sm tw-m-0"
          >
            {{ currentTexts[bookingStep].subtitle }}
          </p>
        </div>
        <div
          class="tw-w-10/12 lg:tw-w-8/12 tw-h-full tw-block tw-mx-auto"
          v-if="bookingStep === 1"
        >
          <hr class="tw-w-full tw-mt-1 tw-mb-3" />
          <comp-selectable-calendar
            @setFromDate="setFromDate"
            @setToDate="setToDate"
            @isHighSeason="bookingIsHighSeason = $event"
          />
          <hr class="tw-w-full tw-mb-4" />
          <div class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-4">
            <comp-button-custom
              text="Cancelar"
              @click.native="closeModal()"
              class="tw-w-full"
            />
            <comp-button-primary-red
              :disabled="bookingStep === 1 && (!startDate || !endDate)"
              title="Siguiente"
              class="tw-w-full"
              @click.native="nextStep()"
            />
          </div>
        </div>
        <div class="tw-w-full tw-h-full tw-mt-2" v-if="bookingStep === 2">
          <div v-if="bookingPlaceOptions.length > 0">
            <div class="tw-flex tw-justify-between tw-items-center">
              <div>
                <p class="tw-m-0 tw-font-robotoBold tw-text-sm lg:tw-text-base">
                  {{
                    `${formatDateDDMMYYYY(startDate)} - ${formatDateDDMMYYYY(
                      endDate
                    )} `
                  }}
                </p>
              </div>
              <div>
                <comp-button-primary-red
                  title="Cambiar Fechas"
                  class="tw-w-auto"
                  @click.native="bookingStep = 1"
                />
              </div>
            </div>
            <div
              class="tw-bg-pink-100 tw-p-3 tw-mt-5"
              v-if="bookingIsHighSeason"
            >
              <p
                class="tw-m-0 tw-font-roboto tw-text-sm lg:tw-text-base tw-text-center"
              >
                Estas seleccionando fechas de temporada alta, por lo cual
                entrarás en un sorteo.
              </p>
            </div>
            <div
              class="tw-h-[400px] tw-overflow-y-scroll tw-mt-2 lg:tw-h-[500px] invisible-scrollbar"
            >
              <div
                class="grid-container tw-grid tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-3 lg:tw-gap-4 tw-justify-center tw-mt-5"
              >
                <comp-reservation-space-booking-card
                  v-for="(item, idx) in bookingPlaceOptions"
                  :key="idx"
                  :item="item"
                  :startDate="startDate"
                  :endDate="endDate"
                  @close="closeModal()"
                  @continueBooking="continueBooking(item)"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4"
          >
            <div class="tw-flex tw-items-center tw-gap-4 lg:tw-gap-6">
              <img
                src="@/assets/img/modal/modal-error.svg"
                alt=""
                class="tw-h-14 lg:tw-h-20"
              />
              <p
                class="tw-m-0 tw-font-robotoBold tw-text-4xl lg:tw-text-5xl tw-text-red-350"
              >
                Lo sentimos
              </p>
            </div>
            <p class="tw-m-0 tw-font-roboto tw-text-base">
              En estas fechas ninguno de nuestros espacios están disponibles.
            </p>
            <comp-button-primary-red
              title="Selecciona otras fechas"
              class="tw-w-auto"
              @click.native="goToStep(1)"
            />
          </div>
        </div>
      </div>
    </general-modal>
  </portal>
</template>

<style scoped>
@media (max-width: 1023px) {
  .grid-container > *:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
    justify-self: center;
    width: calc(50% - 0.5rem);
  }
}
</style>
